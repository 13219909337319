import * as React from 'react'
import { Link } from 'src/design-system'
import { trackEvent } from '../services/event-tracker'
import cn from 'classnames'
import { successToast } from '../utils/success-toast'
import { Tooltip } from 'src/design-system'
import { Copy } from '@phosphor-icons/react'

type CopyUrlLinkProps = {
  id: number
  type: string
  url: string
  className?: string
}

export const CopyUrlLink: React.VFC<CopyUrlLinkProps> = (props) => {
  const { id, type, url, className } = props

  const copyToClipboard = () => {
    if (!url) return
    navigator.clipboard.writeText(url)
    trackEvent('$track_public_framework_url_copied', {
      id,
      type,
      url,
    })
    successToast('Copied to clipboard')
  }

  return (
    <>
      <Tooltip content="Copy URL" side="top">
        <Link
          underline={false}
          className={cn(className, 'whitespace-nowrap cursor-pointer')}
          onClick={copyToClipboard}
          id="copy-link-button"
        >
          <Copy size={16} />
        </Link>
      </Tooltip>
    </>
  )
}
