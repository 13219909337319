import * as React from 'react'
import cn from 'classnames'
import { ArrowRight, Sparkle } from '@phosphor-icons/react'
import { Progress } from 'src/design-system'
import { observer } from 'mobx-react-lite'

export type OnboardingQuickstartButtonProps = {
  completedSteps: number
  totalSteps: number
  className?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const BaseOnboardingQuickstartButton = React.forwardRef<
  HTMLButtonElement,
  OnboardingQuickstartButtonProps
>((props, ref) => {
  const { completedSteps, totalSteps, className, ...restProps } = props

  const completedPercentage = (completedSteps / totalSteps) * 100

  return (
    <button
      ref={ref}
      type="button"
      className={cn(
        'rounded bg-pink-100 hover:bg-pink-200 group px-2 py-3 flex flex-row items-center gap-x-3 transition-colors',
        className
      )}
      {...restProps}
    >
      <div className="flex flex-row items-center gap-x-1.5">
        <Sparkle
          weight="fill"
          className="w-3.5 h-3.5 text-pink-600"
          aria-hidden
        />
        <span className="text-gray-900 text-sm font-semibold">Quickstart</span>
        <ArrowRight
          weight="bold"
          className="w-3.5 h-3.5 text-pink-200 group-hover:text-pink-300 transition"
          aria-hidden
        />
      </div>
      <Progress
        wrapperClassName="w-full"
        indicatorClassName="bg-pink-600"
        rootClassName="w-full h-1 bg-white"
        value={completedPercentage}
      />
      <span className="text-pink-700 px-1.5">
        {completedSteps}/{totalSteps}{' '}
        <span className="sr-only">steps completed</span>
      </span>
    </button>
  )
})

BaseOnboardingQuickstartButton.displayName = 'OnboardingQuickstartButton'

export const OnboardingQuickstartButton = observer(
  BaseOnboardingQuickstartButton
)
