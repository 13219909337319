import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { OnboardingChecklistStep } from './onboarding-checklist-step'
import { OnboardingChecklistItem } from './onboarding-checklist.types'

export type OnboardingChecklistProps = {
  steps: OnboardingChecklistItem[]
}

export const OnboardingChecklist = observer(
  (props: OnboardingChecklistProps) => {
    const { steps } = props

    const hasFramework = store.teams.forOrg.length > 0

    return (
      <div className="relative mx-5 my-4">
        <div className="absolute top-2 left-2 w-px h-[calc(100%-1.5rem)] bg-gray-100 z-[-1]" />
        <ul className="list-none pl-0 flex flex-col gap-y-4">
          {steps.map((step) => (
            <OnboardingChecklistStep
              step={step}
              key={step.title}
              disabled={step.title !== 'Create Framework' && !hasFramework}
            />
          ))}
        </ul>
      </div>
    )
  }
)
