import * as React from 'react'
import { Drawer } from 'src/design-system'
import { OnboardingQuickstartButton } from './quickstart-button'
import { Sparkle, X } from '@phosphor-icons/react'
import { OnboardingChecklist } from './onboarding-checklist'
import { store } from 'store/index'
import { observer } from 'mobx-react-lite'
import { OnboardingSteps } from './onboarding-steps'
import { trackEvent } from '../../services/event-tracker'

export const ONBOARDING_CHECKLIST_UPDATED_KEY = 'onboarding-checklist-updated'

export type OnboardingChecklistDrawerProps = {
  triggerClassName?: string
}

export const OnboardingChecklistDrawer = observer(
  (props: OnboardingChecklistDrawerProps) => {
    const { triggerClassName } = props

    React.useEffect(() => {
      async function fetchTeams() {
        await store.teams.fetchAll(
          {
            include: [
              'docs',
              'editors',
              'framework',
              'open_positions',
              'users',
            ],
          },
          { bypassCache: true }
        )
      }

      document.addEventListener(ONBOARDING_CHECKLIST_UPDATED_KEY, fetchTeams)

      return () => {
        document.removeEventListener(
          ONBOARDING_CHECKLIST_UPDATED_KEY,
          fetchTeams
        )
      }
    }, [])

    const onboardingSteps = new OnboardingSteps()

    const dateToShowOnboardingChecklistFrom = new Date(
      '2023-03-01'
    ).toISOString()

    const showOnboardingChecklist =
      store.featureFlags.featureEnabled('show_onboarding_checklist') &&
      !onboardingSteps.fullyCompleted &&
      store.currentUser?.isAdmin &&
      store.currentUser?.org &&
      store.currentUser.org.createdAt.toISOString() >
        dateToShowOnboardingChecklistFrom

    if (!showOnboardingChecklist) return null

    return (
      <Drawer
        title="Quickstart"
        animate={false}
        direction="left"
        overlay={false}
        className="quickstart-drawer md:left-[291px] w-[290px] !shadow-none !outline-none border-0 border-r border-solid border-gray-100"
        trigger={
          <OnboardingQuickstartButton
            completedSteps={onboardingSteps.completedSteps}
            totalSteps={onboardingSteps.totalSteps}
            className={triggerClassName}
            onClick={() => {
              trackEvent('$onboarding_checklist_opened')
            }}
          />
        }
      >
        {({ onClose }) => (
          <>
            <header className="flex flex-row items-center h-14 justify-between gap-x-1.5 px-5 border-0 border-b border-gray-100 border-solid">
              <div className="flex flex-row items-center gap-x-1.5">
                <Sparkle weight="fill" className="text-pink-600 h-3.5 w-3.5" />
                <h5 className="text-base font-bold text-gray-900">
                  Quickstart
                </h5>
              </div>

              <button onClick={onClose} type="button" className="p-1">
                <X
                  weight="bold"
                  className="text-gray-900 h-4 w-4"
                  aria-hidden
                />
                <span className="sr-only">Close quickstart drawer</span>
              </button>
            </header>

            <div className="text-gray-900 px-5 py-4 font-bold h-14 border-0 border-b border-solid border-gray-100">
              Build your first Framework
            </div>

            <OnboardingChecklist steps={onboardingSteps.steps} />
          </>
        )}
      </Drawer>
    )
  }
)
