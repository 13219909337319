import { store } from 'store/index'
import { openModal } from '../../utils/open-modal'
import { OnboardingChecklistItem } from './onboarding-checklist.types'

export class OnboardingSteps {
  private watchedTourKey = 'progression_watched_fw_tour'
  private frameworkPath = '/review-your-framework'
  private shareModalFwPath = `${this.frameworkPath}?open_share_modal=true`
  private frameworkTourPath = `${this.frameworkPath}?product_tour_id=415105`
  private visibilityDropdownPath = `${this.frameworkPath}?open_visibility_dropdown=true`
  private invitePath = `${this.frameworkPath}?highlight_first_add_user=true`

  get createFrameworkStep(): OnboardingChecklistItem {
    return {
      title: 'Create Framework',
      completed: store.teams.forOrg.length > 0,
      onClick: () => openModal('/teams/new'),
    }
  }

  get watchFrameworkTourStep(): OnboardingChecklistItem {
    return {
      title: 'Watch Framework tour',
      completed: localStorage.getItem(this.watchedTourKey) === 'true',
      href: this.frameworkTourPath,
      onClick: () => localStorage.setItem(this.watchedTourKey, 'true'),
    }
  }

  get shareFrameworkStep(): OnboardingChecklistItem {
    return {
      title: 'Share Framework with collaborators',
      completed: store.teams.hasEditors(),
      href: this.shareModalFwPath,
    }
  }

  get putFrameworkLiveStep(): OnboardingChecklistItem {
    return {
      title: 'Put Framework Live',
      completed: store.teams.hasPublishedFrameworks,
      href: this.visibilityDropdownPath,
    }
  }

  get inviteTeamStep(): OnboardingChecklistItem {
    return {
      title: 'Invite team to positions',
      completed: store.teams.hasUsersInPositions(),
      href: this.invitePath,
    }
  }

  steps = [
    this.createFrameworkStep,
    this.watchFrameworkTourStep,
    this.shareFrameworkStep,
    this.putFrameworkLiveStep,
    this.inviteTeamStep,
  ]

  get completedSteps(): number {
    return this.steps.filter((step) => step.completed).length
  }

  get totalSteps(): number {
    return this.steps.length
  }

  get fullyCompleted(): boolean {
    return this.steps.every((step) => step.completed)
  }
}
