import * as React from 'react'

export const IncompleteIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="-ml-px flex-shrink-0"
  >
    <circle
      cx="10"
      cy="10"
      r="7"
      fill="white"
      className="stroke-current text-gray-100"
      strokeWidth="2"
    />
    <rect
      x="1"
      y="1"
      width="18"
      height="18"
      rx="9"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
)
