import * as React from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { trackEvent } from '../../services/event-tracker'
import { CompletedIcon } from './icons/completed'
import { IncompleteIcon } from './icons/incomplete'
import { OnboardingChecklistItem } from './onboarding-checklist.types'
import { ArrowRight } from '@phosphor-icons/react'

export type OnboardingChecklistStepProps = {
  step: OnboardingChecklistItem
  disabled?: boolean
}

export const OnboardingChecklistStep = observer(
  (props: OnboardingChecklistStepProps) => {
    const { step, disabled = true } = props

    if (step.completed)
      return (
        <li className="-ml-px flex flex-row items-center gap-x-3">
          <CompletedIcon />
          <span className="line-through text-gray-600">
            {step.title}
            <span className="sr-only"> (completed)</span>
          </span>
        </li>
      )

    const trackingEventName = '$onboarding_checklist_click_step'

    const onClick = () => {
      if (step.completed || disabled) return undefined

      step.onClick?.()

      trackEvent(trackingEventName, {
        title: step.title,
      })
    }

    const Tag = step.href ? 'a' : 'button'

    const tagSpecificProps = step.href
      ? { href: disabled ? undefined : step.href }
      : { type: 'button' as const }

    return (
      <li>
        <Tag
          {...tagSpecificProps}
          className={cn(
            'w-full flex flex-row items-center gap-x-3 text-left transition-colors',
            disabled
              ? 'cursor-not-allowed text-gray-600 hover:text-gray-600'
              : 'text-gray-900 hover:text-gray-700'
          )}
          onClick={onClick}
        >
          <IncompleteIcon />
          {step.title}
          <ArrowRight
            weight="bold"
            className="text-gray-600 w-4 h-4 ml-auto flex-shrink-0"
          />
        </Tag>
      </li>
    )
  }
)
